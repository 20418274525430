import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify/lib/framework'
import pt from "vuetify/src/locale/pt.ts";
import Notifications from "vue-notification";
import 'vuetify/dist/vuetify.min.css';
import VueSplide from '@splidejs/vue-splide';
import VMask from 'v-mask';
import router from './router';
import axios from "axios";
import store from './store';
import { initializeApp } from 'firebase/app';
import * as debounce from "lodash.debounce";
import VuetifyMoney from "vuetify-money";

const firebaseConfig = {
  apiKey: "AIzaSyDx9KOvB1Ftdi5Bppywj6LbHh0o5PLvG3M",
  authDomain: "login-bancodebolsas.firebaseapp.com",
  projectId: "login-bancodebolsas",
  storageBucket: "login-bancodebolsas.appspot.com",
  messagingSenderId: "762728802945",
  appId: "1:762728802945:web:e421a699a4742c52d13b80",
  measurementId: "G-1FV1MSYD4R"
};

initializeApp(firebaseConfig);


Vue.use(Vuetify);
Vue.use(VueSplide);
Vue.use(Notifications);
Vue.use(VMask);

axios.defaults.baseURL = `${process.env.VUE_APP_APIURL}/api`;
function updateAxiosHeaders() {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    Authorization: `${localStorage.getItem('token')}`,
  };
}
updateAxiosHeaders();
Vue.prototype.$http = axios;
Vue.prototype.$updateAxiosHeaders = updateAxiosHeaders;

Vue.prototype.$remoteUrl = function (v) {
  if (!v) return null;
  if (!v.startsWith('/home')) v = `/${v}`;
  return `${process.env.VUE_APP_AWS_BUCKET_URL}${v}`;
};

Vue.prototype.$states = [
  { value: "AC", name: "Acre" },
  { value: "AL", name: "Alagoas" },
  { value: "AP", name: "Amapá" },
  { value: "AM", name: "Amazonas" },
  { value: "BA", name: "Bahia" },
  { value: "CE", name: "Ceará" },
  { value: "DF", name: "Distrito Federal" },
  { value: "ES", name: "Espírito Santo" },
  { value: "GO", name: "Goiás" },
  { value: "MA", name: "Maranhão" },
  { value: "MT", name: "Mato Grosso" },
  { value: "MS", name: "Mato Grosso do Sul" },
  { value: "MG", name: "Minas Gerais" },
  { value: "PA", name: "Pará" },
  { value: "PB", name: "Paraíba" },
  { value: "PR", name: "Paraná" },
  { value: "PE", name: "Pernambuco" },
  { value: "PI", name: "Piauí" },
  { value: "RJ", name: "Rio de Janeiro" },
  { value: "RN", name: "Rio Grande do Norte" },
  { value: "RS", name: "Rio Grande do Sul" },
  { value: "RO", name: "Rondônia" },
  { value: "RR", name: "Roraima" },
  { value: "SC", name: "Santa Catarina" },
  { value: "SP", name: "São Paulo" },
  { value: "SE", name: "Sergipe" },
  { value: "TO", name: "Tocantins" },
];

let _debounces = {};
Vue.prototype.$debounce = (k, f, d) => {
  if (_debounces[k]) _debounces[k]?.cancel();
  _debounces[k] = debounce(() => {
    f();
    delete _debounces[k];
  }, d ?? 650);
  _debounces[k]();
};

Vue.prototype.$tinyMceInit = (height) => {
  return {
    menubar: true,
    language: 'pt_BR',
    height: height ?? 500,
    theme: 'silver',
    convert_urls: false,
    statusbar: false,
    image_title: true,
    automatic_uploads: true,
    images_upload_handler: (
      blobInfo,
      success,
      failure,
      progress
    ) => {
      var xhr, formData;

      xhr = new XMLHttpRequest();
      // xhr.withCredentials = true;
      xhr.open("POST", Vue.prototype.$http.defaults.baseURL + "/upload");
      xhr.setRequestHeader("Authorization", `${localStorage.getItem("token")}`);

      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = function () {
        var json;

        if (xhr.status === 403) {
          failure("HTTP Error: " + xhr.status, { remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure("HTTP Error: " + xhr.status);
          return;
        }

        json = JSON.parse(xhr.responseText);

        if (!json || typeof json.path != "string") {
          failure("Invalid JSON: " + xhr.responseText);
          return;
        }

        success(json.path);
      };

      xhr.onerror = function () {
        failure(
          "Image upload failed due to a XHR Transport error. Code: " + xhr.status
        );
      };

      formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    },
    images_upload_credentials: true,
    relative_urls: false,
    remove_script_host: false,
    document_base_url: process.env.VUE_APP_AWS_BUCKET_URL,
    file_picker_types: 'image',
    plugins: [
      'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help',
    ],
    toolbar:
      'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
  }
};

Vue.use(VuetifyMoney);

Vue.prototype.$moneyOptions = {
  locale: "pt-BR",
  prefix: "R$",
  suffix: "",
  precision: 2,
};

Vue.prototype.$paymentStatuses = {
  0: "Pago",
  1: "Pendente",
  2: "Cancelado",
  3: "Falha",
};

Vue.prototype.$checkPrice = (v, canBeEqualToZero) => {
  if (!v) return false;
  v = v.replaceAll(",", ".");
  let floatValue = Number.parseFloat(v);
  if (canBeEqualToZero) {
    return floatValue >= 0;
  }
  return floatValue > 0;
};

Vue.prototype.$price = (value, currency = 'BRL', locale = 'pt-BR') => {
  if (!value) value = 0;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(value);
}

Vue.prototype.$defaultImg = 'bancodebolsas/6fe99df8-3a45-4e91-af22-ceb337ebb4222681831-removebg-preview.png';

Vue.prototype.$mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-a-w)|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-2-7|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

Vue.prototype.$getDate = function (dateString) {
  if (!dateString) return "Data inválida ou não informada";
  var date = new Date(dateString);
  if (date && date?.toLocaleDateString() != "Invalid Date") {
    return date.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  }
  return "--";
};
Vue.prototype.$getDateTime = function (datetime) {
  if (!datetime) return "Data/Hora inválida ou não informada";
  let date = new Date(Date.parse(datetime));
  if (date && date?.toLocaleDateString() != "Invalid Date") {
    return `${date?.toLocaleDateString("pt-BR")} às ${date.toLocaleTimeString(
      "pt-BR",
      { timeStyle: "short" }
    )}`;
  }
  return "--";
};

Vue.prototype.$scholarLevels = [
  { name: "Graduação", value: 0 },
  { name: "Tecnólogo", value: 1 },
  { name: "Bacharelado", value: 2 },
  { name: "Licenciatura", value: 3 },
  { name: "Pós-Graduação", value: 4 },
  { name: "Especialização", value: 5 },
  { name: "MBA", value: 6 },
];
Vue.prototype.$scholarTypes = [
  { name: "EAD", value: 0 },
  { name: "Híbrido", value: 1 },
  { name: "Presencial", value: 2 },
];
Vue.prototype.$scholarPeriods = [
  { name: "Manhã", value: 0 },
  { name: "Tarde", value: 1 },
  { name: "Noite", value: 2 },
];

const vuetify = new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
