<template>
  <v-app>
    <HeaderComponent v-if="!isRouteMaintenance" />
    <v-main>
      <v-overlay :value="loadingRoute">
        <v-row class="ma-0" justify="center" align="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-row>
        <v-row class="ma-0" justify="center" align="center">
          <h5 class="mb-0 mt-2">Carregando</h5>
        </v-row>
      </v-overlay>
      <router-view></router-view>
      <notifications group="app" />
    </v-main>
    <FooterComponent v-if="!isRouteMaintenance" />
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {
    HeaderComponent: () => import("./components/header/HeaderComponent.vue"),
    FooterComponent: () => import("./components/FooterComponent.vue"),
  },
  created() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.$store.commit("setMobile", this.$mobileAndTabletCheck());
    },
  },
  computed:{
    loadingRoute() {
      return this.$store?.state?.loadingRoute;
    },
    isRouteMaintenance(){
      return this.$route.path === '/';
    },
  }
};
</script>

