import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'MaintenancePage',
    component: () => import('./pages/MaintenancePage.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('./pages/homePage.vue'),
  },
  {
    path: '/#quemsomos',
    name: 'QuemSomosHome',
    component: () => import('./pages/homePage.vue'),
  },
  {
    path: '/search',
    name: 'SearchResult',
    component: () => import('./pages/searchResultPage.vue'),
  },
  {
    path: '/faculdade/:name',
    name: 'Faculdade',
    component: () => import('./pages/faculdadePage.vue'),
  },
  {
    path: '/faculdade/:name/curso/:id',
    name: 'CursoPage',
    component: () => import('./pages/cursoPage.vue'),
    props: true,
  },
  {
    path: '/faculdade/:name/curso/:id/preencher-dados-pessoais',
    name: 'PreencherDadosPessoais',
    component: () => import('./pages/registerDadosPage.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('./pages/loginPage.vue'),
  },
  {
    path: '/create_account',
    name: 'CreateAccount',
    component: () => import('./pages/createAccountPage.vue'),
  },
  {
    path: '/my_account',
    name: 'MyAccount',
    component: () => import('./pages/myAccountPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/my_profile',
    name: 'MyProfile',
    component: () => import('./pages/myProfilePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/minhas-bolsas',
    name: 'MyScholarships',
    component: () => import('./pages/myScholarshipsPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/minhas-bolsas/:id/detalhes',
    name: 'DetailsScholarship',
    component: () => import('./pages/PurchaseDetailsPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/fale-conosco',
    name: 'FaleConosco',
    component: () => import('./pages/faleConoscoPage.vue'),
  },
  {
    path: '/quem-somos',
    name: 'QuemSomos',
    component: () => import('./pages/quemSomosPage.vue'),
  },
  {
    path: '/admin/universities',
    name: 'Universities',
    component: () => import('./pages/Admin/University/ListItems.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/universities/new',
    name: 'newUnivesityPage',
    component: () => import('./pages/Admin/University/FormData.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/universities/:id',
    name: 'editUnivesityPage',
    component: () => import('./pages/Admin/University/FormData.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },


  {
    path: '/admin/polos',
    name: 'Polos',
    component: () => import('./pages/Admin/Polo/ListItems.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/polos/new',
    name: 'newPoloPage',
    component: () => import('./pages/Admin/Polo/FormData.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/polos/:id',
    name: 'editPoloPage',
    component: () => import('./pages/Admin/Polo/FormData.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },

  {
    path: '/admin/cursos',
    name: 'cursosPage',
    component: () => import('./pages/Admin/Courses/ListItems.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/cursos/novo',
    name: 'cursosPageNew',
    component: () => import('./pages/Admin/Courses/FormData.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/cursos/:id',
    name: 'cursosPageEdit',
    component: () => import('./pages/Admin/Courses/FormData.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },

  {
    path: '/admin/bolsas',
    name: 'bolsasPage',
    component: () => import('./pages/Admin/Scholarship/ListItems.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/bolsas/nova',
    name: 'bolsasPageNew',
    component: () => import('./pages/Admin/Scholarship/FormData.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/bolsas/:id',
    name: 'bolsasPageEdit',
    component: () => import('./pages/Admin/Scholarship/FormData.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/bolsas/:id/vagas',
    name: 'bolsasVagasPage',
    component: () => import('./pages/Admin/Scholarship/VacanciesList.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
  },
  {
    path: '/admin/users',
    name: 'usersPage',
    component: () => import('./pages/Admin/User/ListItems.vue'),
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
    props: true,
  },

  {
    path: '/admin/payments',
    name: 'paymentsPage',
    component: () => import('./pages/Admin/Payment/ListItems.vue'),
    meta: {
      requiresAuth: true,
      needsToBeAdmin: true,
    },
    props: true,
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            let elementPosition;
            // Se já estamos na página, ajustamos a posição de rolagem atual
            if (to.path === from.path) {
              elementPosition = element.getBoundingClientRect().top + window.pageYOffset + -50;
            } else {
              // Caso contrário, calculamos a posição de rolagem como antes
              elementPosition = element.getBoundingClientRect().top + window.pageYOffset + -50;
            }
            resolve({ x: 0, y: elementPosition });
          } else {
            reject(new Error("Element not found"));
          }
        }, 500); // Ajuste esse atraso conforme necessário
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
});

const getUserData = async () => {
  try {
    store.commit('setLoadingUserInfo', true);
    await Vue.prototype.$http.get('/auth/me/').then((r) => {
      store.commit('setUserInfo', r?.data);
      store.commit('setLoadingUserInfo', false);
    }).catch((e) => {
      store.commit('setLoadingUserInfo', false);
      store.commit('setUserInfo', null);
      console.log("error get user data router: ", { e });
    });
  } catch (error) {
    store.commit('setLoadingUserInfo', false);
    store.commit('setUserInfo', null);
    console.log("general error get user data router: ", { error })
  }
}

router.beforeEach(async (to, from, next) => {
  store.commit('setLoading', true);
  // obtém dados do possível usuário logado
  if (!store.getters.isAuthenticated) {
    await getUserData();
  }
  // Verifique se a rota requer autenticação
  if (to.meta.requiresAuth) {
    // obtém dados do possível usuário logado
    if (!store.getters.isAuthenticated) {
      await getUserData();
    }
    // Esta rota requer autenticação, verifique se o usuário está autenticado
    if (!store.getters.isAuthenticated) {
      // Usuário não está autenticado, redirecione para a página de login
      return next({
        path: '/login',
        query: { redirect: to.fullPath } // Opcional: mantenha a rota original como parâmetro de consulta para redirecionar após o login
      });
    }
  }
  if (to.meta.needsToBeAdmin) {
    if (store.state.userInfo?.level != 1) {
      alert(from.path)
      return next(from.path);
    }
  }
  return next();
});
router.afterEach(() => {
  // Desativa o carregamento
  store.commit('setLoading', false);
});

export default router;
