//Vuex - crie para o desenvolvimento do filtro de cursos, no Search... assim no splide ele vai redirecionar levando o valor do filtro...

// store.js 
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedCourseName: '',
    selectedUniversityName: '',
    selectedCourse: null,
    loadingUserInfo: false,
    loadingRoute: false,
    userInfo: null,
    mobile: false,
  },
  mutations: {
    setSelectedCourseName(state, courseName) {
      state.selectedCourseName = courseName;
    },
    setSelectedUniversityName(state, universityName) {
      state.selectedUniversityName = universityName;
    },
    setLoadingUserInfo(state, loadingUserInfo) {
      state.loadingUserInfo = loadingUserInfo;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setLoading: (state, bool) => {
      state.loadingRoute = bool;
    },
    setMobile(state, v) {
      state.mobile = v;
    },
    RESET_USER_STATE(state) {
      state.loadingUserInfo = false;
      state.userInfo = null;
    },
    SET_SELECTED_COURSE(state, course) {
      state.selectedCourse = course;
      if (course) {
        localStorage.setItem('selectedCourse', JSON.stringify(course));
      } else {
        localStorage.removeItem('selectedCourse');
      }
    },

  },
  actions: {
    updateSearchParameters({ commit }, payload) {
      if (payload.courseName !== undefined) {
        commit('setSelectedCourseName', payload.courseName);
      }
      if (payload.universityName !== undefined) {
        commit('setSelectedUniversityName', payload.universityName);
      }
    },
    updateUserInfo({ commit }, userInfo) {
      commit('setUserInfo', userInfo);
    },
    logout({ commit }) {
      commit('RESET_USER_STATE');
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      // Remova a linha abaixo se você não precisa limpar o curso selecionado ao deslogar
      commit('SET_SELECTED_COURSE', null);
    },
    selectCourse({ commit }, course) {
      commit('SET_SELECTED_COURSE', course);
    },


  },

  getters: {
    isAuthenticated(state) {
      return !!state.userInfo;
    },

    token: state => state.token,

  },
});
